import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>MISSING PAGE / VERMISSTE SEITE</h1>
    <p>Please inform us, that the link is incorrect or respectively, that the page is missing.</p>
    <p>Bitte informieren Sie uns, dass die Verknüpfung inkorrekt ist bzw. die Seite vermisst wird.</p>
  </Layout>
)

export default NotFoundPage
